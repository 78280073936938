/*jshint browser: true */
/*jshint esversion: 6 */

import gsap from "gsap";
import { EasePack } from "gsap/EasePack";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {Back, TimelineMax} from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(EasePack, ScrollToPlugin, ScrollTrigger);

window.$ = window.jQuery = require('jquery');

let int = null;

$('input[name="search-input"]').on("keyup", function() {
    let self = this;
    clearTimeout(int);

    int = setTimeout(function() {
        filtrate($(self).val().toLowerCase());
    }, 400);
});

function filtrate(searched) {
    let year = $('#select-by-year').val();

    $(".news-result").filter(function() {

        let isHidden  = ($('p', this).text().toLowerCase().indexOf(searched) > -1) ? 'block' : 'none';
        let isHidden2 = ($('h3', this).text().toLowerCase().indexOf(searched) > -1) ? 'block' : 'none';

        if (year !== '') {
            if (!$(this).hasClass('year-' + year)) {
                $(this).css('display', 'none');
            }
            else {
                if (isHidden === 'block' || isHidden2 === 'block') {
                    $(this).css('display', 'block');
                } else {
                    $(this).css('display', 'none');
                }
            }
        }
        else {
            if (isHidden === 'block' || isHidden2 === 'block') {
                $(this).css('display', 'block');
            } else {
                $(this).css('display', 'none');
            }
        }
    });
}

$('.news-filter #sort-by-date').on('change', function(e) {
    let val = $(this).val();

    if (val === 'oldest') {
        $('.news-results').addClass('reverse');
    } else {
        $('.news-results').removeClass('reverse');
    }
});

$('.news-filter #select-by-year').on('change', function(e) {
    let val = $(this).val();

    if (val === '') {
        $('.news-result').css('display', 'block');
        return;
    }

    $('input[name="search-input"]').val('');

    $('.news-result').css('display', 'none');
    $('.year-' + val).css('display', 'block');
});


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// X.) INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).ready(function () {
    if($(".news-filter").length) {
        console.log('news loaded');
    }
});
