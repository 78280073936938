/*jshint browser: true */
/*jshint esversion: 6 */

// Repo Import / uncomment on Upload
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { EasePack } from "gsap/EasePack";
import { Alert } from "bootstrap";
gsap.registerPlugin(EasePack);
gsap.registerPlugin(MotionPathPlugin);


// BG Video Change on Orientation
let responsiveVideo = () => {
    // Variables
    var video = document.getElementById('bg-video');
    var source = video.getElementsByTagName("source")[0];
    var filename = source.src.split('/').pop().split('-')[0]+ '-';
    var path = source.src.split("/").slice(0, source.src.split("/").length - 1).join("/") + "/";
    
    // Media Querries
    var landscape_s = window.matchMedia('(min-width:0px) and (max-width:500px)');
    var landscape_m = window.matchMedia('(min-width:500px) and (max-width:991px)');
    var landscape_l = window.matchMedia('(min-width:992px) and (max-width:1599px)');
    var landscape_xl = window.matchMedia('(min-width:1600px)');

    let mediaChange = () => {
        var src = source.src;
        //landscape_s
        if (landscape_s.matches && src != path + filename + 'landscape_s' + '.mp4') {
            console.log('src old: ' + source.src);
            source.setAttribute('src', path + filename + 'landscape_s' + '.mp4');
        //landscape_m
        } else if (landscape_m.matches && src != path + filename + 'landscape_m' + '.mp4') {
            console.log('src old: ' + source.src);
            source.setAttribute('src', path + filename + 'landscape_m' + '.mp4');
        //landscape_l
        } else if (landscape_l.matches && src != path + filename + 'landscape_l' + '.mp4') {
            console.log('src old: ' + source.src);
            source.setAttribute('src', path + filename + 'landscape_l' + '.mp4');
        //landscape_xl
        } else if (landscape_xl.matches && src != path + filename + 'landscape_xl' + '.mp4') {
            console.log('src old: ' + source.src);
            source.setAttribute('src', path + filename + 'landscape_xl' + '.mp4');
        }
        console.log('src new: ' + source.src);
        video.load();
    }
    mediaChange();
    landscape_s.addEventListener('change', mediaChange);
    landscape_m.addEventListener('change', mediaChange);
    landscape_l.addEventListener('change', mediaChange);
    landscape_xl.addEventListener('change', mediaChange);



    //Volume Toggle
    $('html .video-hero .btn-volume-toggle').click( function (){
        if (video.muted === true) {
            video.muted = false;
        } else if (video.muted === false) {
            video.muted = true;
        }
    });
    video.addEventListener('volumechange', function () {
        if (video.muted) {
            $('.btn-volume-toggle').toggleClass('hidden');
        $('.btn-volume-toggle').toggleClass('active');

        } else {
            $('.btn-volume-toggle').toggleClass('hidden');
            $('.btn-volume-toggle').toggleClass('active');
        }
    }, false);

    //Play/Pause Toggle
    $('html .video-hero .btn-play-toggle').click( function (){
        if (video.paused) {
            video.play(); 
        } else { 
            video.pause();
        }
        $('.btn-play-toggle').toggleClass('active');
    });
    video.onplay  = function() {
        $('.btn-play-toggle').removeClass('active');
    };  
    video.onpause  = function() {
        $('.btn-play-toggle').addClass('active');
    };

    



    // //Events
    // // start Video on refocus
    // var visibilityHidden, visibilityChange;
    // // Opera 12.10 and Firefox 18 and later support 
    // if (typeof document.hidden !== "undefined") {
    //     visibilityHidden = "hidden";
    //     visibilityChange = "visibilitychange";
    // } else if (typeof document.mozHidden !== "undefined") {
    //     visibilityHidden = "mozHidden";
    //     visibilityChange = "mozvisibilitychange";
    // } else if (typeof document.msHidden !== "undefined") {
    //     visibilityHidden = "msHidden";
    //     visibilityChange = "msvisibilitychange";
    // } else if (typeof document.webkitHidden !== "undefined") {
    //     visibilityHidden = "webkitHidden";
    //     visibilityChange = "webkitvisibilitychange";
    // }

    $('html .video-hero .btn-fullscreen').click( function (event){
        if (video.muted === true) {
            video.muted = false;
        }
        if (window.innerWidth < 992 && $('html').hasClass('non-touch') || video.webkitRequestFullscreen && !$('html').hasClass('non-touch') ) {
            return;
        } 
        if (video.requestFullscreen) { /* Firefox etc.*/
                event.preventDefault();
                event.stopPropagation();
                video.requestFullscreen();
        } else if (video.msRequestFullscreen) { /* IE11 */
                event.preventDefault();
                event.stopPropagation();
                video.msRequestFullscreen();
        } else if (video.webkitRequestFullscreen) { /* Safari */
                event.preventDefault();
                event.stopPropagation();
                video.webkitRequestFullscreen();
        }

    });
    $('html .video-hero .btn-scrub-bkw').click( function (event) {
        video.currentTime -= 20;
    });
    $('html .video-hero .btn-scrub-fwd').click( function (event) {
        video.currentTime += 20;
    });

}

//////////////////////////////////
////////////// init //////////////
//////////////////////////////////
jQuery(document).ready(function () {
    if ( document.getElementById("bg-video") != null ) {
        responsiveVideo();
    }
});
