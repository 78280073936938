/*jshint browser: true */
/*jshint esversion: 6 */

/**
 * Global ajax setup for token-security
 */
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        'Accept': 'application/json'
    }
});


$('#modal-deleteDialogue a').on('click', function(ev) {

    ev.stopPropagation();
    ev.preventDefault();

    $('#modal-deleteDialogue .feedback').html('<p><h2><b>Please wait...</b></h2>We are deleting your account-data.</p>');
    $('#modal-deleteDialogue i').hide();
    $('#modal-deleteDialogue a').hide();
    $('#modal-deleteDialogue h3').hide();
    $('#modal-deleteDialogue h6').hide();

    jQuery.post('/unregister')
        .done(response => {
            $('#modal-deleteConfirm').modal('show');
            $('#modal-deleteDialogue').modal('hide');

            setTimeout(function() {
                window.location.href='/';
            }, 3000);
        })
        .fail(error => {
            let response = error.responseJSON;
            if (null != response.errors) {
                $('#modal-deleteDialogue .feedback').html(response.errors[Object.keys(response.errors).shift()]);
            }
        });

    return false;
});


$('#form-signUp').submit(function() {

    let data = $('#form-signUp').serialize();

    jQuery.post($('#form-signUp').attr('action'), data)
        .done(response => {
            if (response.user.regGss === 1) {
                window.location.href='/redirect';
            } else {
                jQuery('#modal-signUpSuccess').modal('show');
                document.getElementById("form-signUp").reset();

                setTimeout(function() {
                    window.location.href='/';
                }, 3000);
            }
        })
        .fail(error => {
            let response = error.responseJSON;
            if (null != response.errors) {
                $('#modal-signUpError .feedback').html(response.errors[Object.keys(response.errors).shift()]);
                jQuery('#modal-signUpError').modal('show');
            }
        });

    return false;
});


/**
 * Callback handler for user-login
 */
$('#signInform').submit(function() {

    let pass = $('#signInform #password').val();
    let user = $('#signInform #login').val();

    jQuery.post($('#signInform').attr('action'), {username: user, password: pass})
        .done(response => {
            window.location.href = '/';
        })
        .fail(error => {
            let response = error.responseJSON;
            if (null != response.errors) {
                $('#modal-signIn .feedback').html(response.errors[Object.keys(response.errors).shift()]);
            }
        });

    return false;
});

/**
 * Callback handler for user-login
 */
$('#quick-signin').submit(function() {

    let pass = $('#quick-signin #password').val();
    let user = $('#quick-signin #login').val();

    jQuery.post($('#quick-signin').attr('action'), {username: user, password: pass})
        .done(response => {
            window.location.href = '/';
        })
        .fail(error => {
            let response = error.responseJSON;
            if (null != response.errors) {
                $('.feedback-login').show();
            }
        });

    return false;
});

/**
 * Callback handler for user-password reset
 */
$('#newPsswdform').submit(function() {

    let token = $('meta[name="csrf-token"]').attr('content');
    let user = $('#newPsswdform #login').val();

    jQuery.post($('#newPsswdform').attr('action'), { email: user })
        .done(response => {
            $('#modal-newPsswd').modal('hide');
            $('#modal-sendConfirm').modal('show');
        })
        .fail(error => {
            let response = error.responseJSON;
            if (null != response.errors) {
                $('#modal-newPsswd .feedback').html(response.errors[Object.keys(response.errors).shift()]);
            }
        });

    return false;
});

/**
 * Creates a new cookie with the given name, value, and expiration period.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to be stored in the cookie.
 * @param {number} days - The number of days until the cookie expires. If not provided or set to 0, the cookie will
 * expire at the end of the session.
 */
function createCookie(name, value, days) {
    let expires;

    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

$('a[data-toggle="modal"]').on('click', function() {
    let redirect = $(this).attr('data-redirect');
    if (redirect && redirect !== '') {
        if (redirect === 'events') {
            createCookie('create_event', 'true', 1);
        }
    }
});

