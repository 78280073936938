// Repo Import / uncomment on Upload

import gsap from "gsap";
import { EasePack } from "gsap/EasePack";
gsap.registerPlugin(EasePack);


////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1.) Bopotstrap Modal Custom Animation
////////////////////////////////////////////////////////////////////////////////////////////////////////////
console.log("form-SignUp.js loaded");

var remove_required = function (e) {
    console.log('remove_rquired');
    e.find('.required').removeAttr('required');
};
var add_required = function (e) {
    console.log('add_rquired');
    e.find('.required').attr('required', '');
};


var institutionToggle = function () {


    var section_active;

    // Select with Butttons
    // remove_required($('#regInstitution'));
    // $('#btn-regInstitution').on('click', function (el) {
    //     $('#regInstitution').collapse('show');
    //     $('#regPerson').collapse('hide');

    //     add_required($('#regInstitution'));
    //     remove_required($('#regPerson'));

    //     $('.btn-active').removeClass( 'btn-active' );
    //     $('#btn-regInstitution').addClass( 'btn-active' );

    //     $('#reg_type').val('institution');
    // });
    // $('#btn-regPerson').on('click', function (el) {
    //     $('#regPerson').collapse('show');
    //     $('#regInstitution').collapse('hide');

    //     add_required($('#regPerson'));
    //     remove_required($('#regInstitution'));

    //     $('.btn-active').removeClass( 'btn-active' );
    //     $('#btn-regPerson').addClass( 'btn-active' );

    //     $('#reg_type').val('person');
    // });

    // const selectElement = document.querySelector('#person_institution');

    // Select with Select List
    var ddl = document.getElementById("person_institution");
    var selectedValue = ddl.options[ddl.selectedIndex].value;

    if (selectedValue == "institution") {
        console.log('institution selected');
        $('#regInstitution').collapse('show');
        $('#regPerson').collapse('hide');

        $('.btn-active').removeClass( 'btn-active' );
        $('#btn-regInstitution').addClass( 'btn-active' );

        add_required($('#regInstitution'));
        remove_required($('#regPerson'));

        add_required($('#personal-info'));
        $('#personal-info .optional').addClass( 'hidden' );

        $('#reg_type').val('institution');


    } else if (selectedValue == "person") {
        console.log('person selected');
        $('#regPerson').collapse('show');
        $('#regInstitution').collapse('hide');

        $('.btn-active').removeClass( 'btn-active' );
        $('#btn-regPerson').addClass( 'btn-active' );

        add_required($('#regPerson'));
        remove_required($('#regInstitution'));

        remove_required($('#personal-info'));
        $('#personal-info .optional').removeClass( 'hidden' );

        $('#reg_type').val('person');
    }
};

if (document.querySelector('#person_institution') != null) {
    document.querySelector('#person_institution').addEventListener('change', (event) => {
        institutionToggle();
    });
}

var collapseToggle = function () {

    if (document.getElementById('selectUserGroup')) {
        
        window.regGss_collapse_collapsed = true;
        window.gssLogin_collapse_collapsed = true;
        window.regAtlas_collapsed = true;

        var collapse_show = function (el, trigger_value ,target) {
            console.log('collapse show triggered');

            var collapsed = window[target.attr('id') +  '_collapsed'];

            if (el.value == trigger_value && collapsed ) {
                target.collapse('show');
                window[target.attr('id') +  '_collapsed'] = false;

            } else if (el.value != trigger_value && !collapsed ) {
                target.collapse('hide');
                window[target.attr('id') +  '_collapsed'] = true;
            }
        };

        var collapse_hide = function (el, trigger_value ,target) {
            console.log('collapse hide triggered');

            var collapsed = window[target.attr('id') +  '_collapsed'];

            if (el.value == trigger_value && !collapsed ) {
                target.collapse('hide');
                window[target.attr('id') +  '_collapsed'] = true;

            } else if (el.value != trigger_value && collapsed ) {
                target.collapse('show');
                window[target.attr('id') +  '_collapsed'] = false;
            }
        };


        // Select List Sector / User Group 
        var select_usergroup = document.querySelector('#selectUserGroup');
        select_usergroup.addEventListener('change', function() {
            console.log('selected: ' + this.value);
            collapse_hide(this, 'student' ,$('#regAtlas')); // Hide Atlas when Student
            collapse_show(this, 'innovators/startups' ,$('#regGss_collapse')); // Show regGss Up when innovators/startups
        }, false);

        //Radio Global StartUp Member > show regGss
        var regGss = document.querySelector('input[name = "regGss"]:checked');
        $("input[name='regGss']").each(function() {
            this.addEventListener('change', function() {
                collapse_show(this, '2', $('#gssLogin_collapse'));
            }, false);
        });

        // var regGss = document.querySelector('#selectUserGroup')
        jQuery(document).ready(function () {
            collapse_show(select_usergroup , 'innovators/startups' , $('#regGss_collapse')); //show regGss_collapse on Start when usergroup is innovators
            collapse_show(regGss, '2' , $('#gssLogin_collapse')); // show regGss 
            collapse_show(select_usergroup, '' , $('#regAtlas')); //show regAtlas on Start when usergroup is unselected
            //$('#regAtlas').collapse('show');
        });
    }
};

var showFilename = function () {
    document.querySelector('.custom-file-input').addEventListener('click',function(e){
        jQuery('#cropper').modal('show');
        if (jQuery('#custom-file-browse').text() === 'Select File') {
            jQuery(window).trigger('croppa-image-show-chooser');
        }
    });
};

var websiteCheck = function () {
    var input_field = document.getElementById('website');
    var value = input_field.value;

    console.log('websitecheck!, Original Value: ' + value);
    // anaylise Value

    var www = value.split('.')[0];

    console.log('websitecheck!, Value: ' + value + 'wwww:' + www);

    var http = value.split('/')[0];

    console.log('websitecheck!, Value: ' + value + 'wwww:' + www + 'http:' + http);

    if ( value.split('.')[0] == "www") {
        value = 'https://' + value;
        input_field.value = value;
    }

    if (value.split('/')[0] == "http:") {
        value = value.substring(value.indexOf("/"));
        value = 'https://' + value;
        input_field.value = value;
    }

    console.log('new value: ' + value)


};
$('#form-signUp #website').change(function() {
    websiteCheck();
});



///////////////// Form Reg Event //////////
var regPhysEventToggle = function () {
    var section_active;
    // remove_required($('#regInstitution'));
    $('#btn-regPhysEvent').on('click', function (el) {
        // $('#regPhysEvent').addClass('show');
        $('#regOnlineEvent').collapse('hide');
        $('#regPhysEvent').collapse('show');
        $('.btn-active').removeClass( 'btn-active' );
        $('#btn-regPhysEvent').addClass( 'btn-active' );

        add_required($('#regPhysEvent'));
        remove_required($('#regOnlineEvent'));

        $('#event-type').val('physEvent');
    });
    $('#btn-regOnlineEvent').on('click', function (el) {
        $('#regPhysEvent').collapse('hide');
        $('#regOnlineEvent').collapse('show')
        $('.btn-active').removeClass( 'btn-active' );
        $('#btn-regOnlineEvent').addClass( 'btn-active' );

        add_required($('#regOnlineEvent'));
        remove_required($('#regPhysEvent'));

        $('#event-type').val('onlineEvent');
    });
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// X.) INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).ready(function () {
    if(document.getElementById('form-signUp')) {
        institutionToggle();
        showFilename();
        collapseToggle();
        $('#btn-regInstitution').trigger('click');
    }

    if (document.getElementById('form-reg-event')) {
        regPhysEventToggle();
        if (jQuery('#event-type').val() === 'physEvent') {
            $('#btn-regPhysEvent').trigger('click');
        } else {
            $('#btn-regOnlineEvent').trigger('click');
        }
    }

    jQuery("#inputPhone").intlTelInput({
        preferredCountries: [],
        initialCountry: "",
        separateDialCode: true
    });

    jQuery("#inputPhone").on("countrychange", function(ev) {
        if (window.intlTelInputGlobals.instances[0].getSelectedCountryData().iso2 != null) {
            var country = window.intlTelInputGlobals.instances[0].getSelectedCountryData().iso2.toUpperCase();
            document.getElementById('selectInstitutionCountry').value = country;
        } else {
            document.getElementById('selectInstitutionCountry').value = "";
        }
    });

    jQuery('#selectInstitutionCountry').change(function() {
        window.intlTelInputGlobals.instances[0].setCountry(jQuery('#selectInstitutionCountry').val());
    });

});
