////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////
// Style & Grace CSS-class responsive carousel
// Author: Nils Grassmann
// Copyright Style & Grace – Design & Advertising 2018 / 2019
////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////


///////////////////////////////////////////////////////////////////////
// Settings
///////////////////////////////////////////////////////////////////////
/*global $, jQuery, alert, console, TweenLite*/
/*jshint browser: true */
/*jshint esversion: 6 */

// Repo Import / uncomment on Upload
import gsap from 'gsap';
import { TweenLite } from 'gsap';
import { EasePack } from 'gsap/EasePack';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(EasePack);
gsap.registerPlugin(ScrollTrigger);

var hero_init = function () {
    // set Presets
    const easingeffect_preset = 'easeInOut';
    const transitiontime_preset = 7;
    const slidetime_preset = 15;
    let slidetime;
    let transitiontime;
    let easingeffect;

    // read Vars – replace wirth preset if no Var is found
    if ( $('#hero.img-hero').attr('data-slidetime') ) {
        slidetime = Number( $('#hero.img-hero').attr('data-slidetime'));
    } else {
        slidetime = slidetime_preset;
    }
    if ( $('#hero.img-hero').attr('data-transtime') ) {
        transitiontime = Number($('#hero.img-hero').attr('data-transtime'));
    } else {
        transitiontime = transitiontime_preset;
    }
    if ( $('#hero.img-hero').attr('data-easingeffect') ) {
        easingeffect = $('#hero.img-hero').attr('data-easingeffect');
    } else {
        easingeffect = easingeffect_preset;
    }

    const img_total =  $('#hero.img-hero .img-cont .img-el').length - 1;
    let img_actual = 0;
    var img_next = 1;

   //Prepare Slide z-index
    var i;
    for (i = 0; i <= img_total; i += 1) {
        $('#hero.img-hero .img-cont .img-el:eq(' + i + ')').css({
            'z-index': img_total - i
        });
    }

    function trans_fade(img_actual, img_next, easingeffect, transitiontime) {
        console.log('Transition Fade called: Slide: ' + img_next + ', called easing effect: ' + easingeffect + ', called transitiontime: ' + transitiontime +  ', slidetime:' + slidetime );
        //Prepare actual slide
        $('#hero.img-hero .img-cont .img-el:eq(' + img_actual + ')').css({
            'opacity': 1,
            'z-index': img_total
        });
        //Prepare next slide
        $('#hero.img-hero .img-cont .img-el:eq(' + img_next + ')').css({
            'opacity': 1,
            'z-index': img_total - 1,
            'display': 'block'
        });
        //Fade out actual item
        TweenLite.fromTo($('#hero.img-hero .img-cont .img-el:eq(' + img_actual + ')'), transitiontime, {
            opacity: 1
        }, {
            opacity: 0,
            ease: easingeffect
        });
    }

    var trans_ctrl = function () {
        var trans_timer = setInterval(function () {
            if (document.hasFocus()) {
                console.log('img_actual: ' + img_actual + ', img_next: ' + img_next + ', img_total: ' + img_total);
                trans_fade(img_actual, img_next, easingeffect, transitiontime);
                if (img_next < img_total && img_next != img_total && img_actual != img_total) {
                    img_actual++;
                    img_next++;
                } else if (img_next == img_total) {
                    img_next = 0;
                    img_actual++;
                } else if (img_actual == img_total) {
                    img_actual = 0;
                    img_next++;
                }
            }
        }, (slidetime + transitiontime) * 1000);
    };
    if (img_total > 0) {
        trans_ctrl();
    }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// X.) INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).ready(function () {
    if(document.getElementById("hero")){
        hero_init();
    }
});
