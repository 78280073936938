/*jshint browser: true */
/*jshint esversion: 6 */

// Repo Import / uncomment on Upload

import gsap from "gsap";
import { TimelineLite, TimelineMax, Back, Power1, paused, CSSPlugin, yPercent} from "gsap/all";
import { EasePack } from "gsap/EasePack";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(EasePack, ScrollToPlugin);

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1.) Atlas related Animations
////////////////////////////////////////////////////////////////////////////////////////////////////////////
var atlasAnimations = function () {




    console.log('atlas.js loaded');

    // unload loader img
    if($("#map").length){
        $('.atlas-hero .loader').remove();
        
        // // Check for g with transform
        if ($('#map').length) {

            // find el with Scaling Data 
            var role = document.querySelectorAll('[role="alert"]')[0];
            console.log('role length: ' + role.length);
 
      

            const read_circles = function () {
                // Check for Circles
                var circles = $('circle[r="2"]');
                // Set Circle Radius
                circles.attr("r",8)

            }
            const myTimeout = setTimeout(read_circles, 100)

           
            // Change Observer for Role
            var observer = new MutationObserver(function(mutations) {
                console.log(role.innerText);
                 // extract Scale Num
                var scaleNum = role.innerHTML.replace("Zoom level changed to ", "");
                console.log('ScaleNum: ' + scaleNum);

                // Scale Dots
                var dotScale = (1 / scaleNum);
                $("#map").find('circle').css({ transform: 'scale(' + dotScale + ')' })
            });
            observer.observe(role, {
                attributes:    true,
                childList:     true,
                characterData: true
            });
        };
    }

    // create Info Out Timeline
    window.tl_openInfo = new TimelineMax({
        paused: true,
        delay: 0.5
    })
    .from('#atlas-info', 0.5, {
        xPercent: -100,
        autoAlpha: 0,
        ease: Back.easeOut.config(0.75)
    });

    // open .atlas-info
    var info_opened = false;
    $('.atlas-info-open').on('click', function (e) {

        var open_atlasInfo = function () {
            if (window.matchMedia('(max-width: 1200px)').matches){ 
                return;
            }
            tl_openInfo.timeScale(1).play(0);
            info_opened = true;
        };
        var close_atlasInfo = function () {
            if (info_opened) {
                tl_openInfo.seek(0).pause(0);
            }
            atlasToolTip();
        };

        // scroll to Atlas
        gsap.to(window, {
            duration: 0.75,
            scrollTo: '#atlas-anchor',
            ease: "power2.inOut",
            onStart: close_atlasInfo,
            onComplete: open_atlasInfo
        });
    });

    // close .atlas-info
    $('.atlas-info-close').on('click', function (e) {
        console.log('Atlas: close-btn clicked');
        tl_openInfo.delay(5).timeScale(2).reverse();
    });

};

var atlasToolTip = function () {
    if (devicetype != 'non-touch'){
        var tl_tooltip = new TimelineMax({
            // paused: true,
            // delay: 0.75
        })
        .set('.atlas-tooltip',{
            yPercent: -150,
            css: {display: 'block'}
        })
        .fromTo('.atlas-tooltip', 0.75, {
            yPercent: -150
        }, {
            yPercent: 0,
            ease: Power1.easeOut

        }, 1);
        setTimeout(function(){
            tl_tooltip.reverse();
        }, 8000);
    }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// X.) INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////////
jQuery(window).on('atlas-loaded', function () {
    if($("#map").length) {
        atlasAnimations();
        atlasToolTip();
    }
});
