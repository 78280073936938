////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ISC3.org style.scss
// Author: Nils Grassmann
// Copyright: Style & Grace – Design & Advertising (2021)
////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// Table of Contents
////////////////////////////////////////////////////////////////////////////////////////////////////////////

// 1.) SETTINGS (Loading APIs & Functions + jshint Settings)
//
// 2.) Menu Ctrl Function (Show/Hide Menu Sections depending on the actual Menu_Level)
// 2.1.) DeviceType Check
// 2.2.) Menu in/out (Open/Close)
// 2.3.) Menu Toggle/Slide (Slide letf/right)
// X.) Init Function

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1.) SETTINGS
////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*jshint browser: true */
/*jshint esversion: 6 */

console.log('naviagation.js loaded');

// Repo Import / uncomment on Upload
import gsap from "gsap";
import { TimelineLite, TimelineMax, Back, ScrollTrigger  } from "gsap";
import { EasePack } from "gsap/EasePack";
gsap.registerPlugin(EasePack);

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 2.) Menu Ctrl Function (Show/Hide Menu Sections depending on the actual Menu_Level)
////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Device Check
let devicetype;
var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
if (!touchsupport) { // browser doesn't support touch
    document.documentElement.className += "non-touch";
    console.log('Device: non-touch');
    window.devicetype = 'non-touch';
} else {
    console.log('Device: Touch');
    window.devicetype = 'touch';
}

// remove css toggle Class, ad js toogle
$("#nav-toggle").removeAttr('id', '#nav-toggle').attr('id', 'nav-toggle-js');

// set vars
var menu_opened = false;
var menu_lvl = 0;
var menu_busy = false;
var menu_mob_kill = function (){};
var menu_dsk_kill = function (){};



// Crate Sub Menu Functions
var menu_mob = function () {
    console.log('menu_mob active');
    var stagger_li = $('#menu-main').children('li');
    // Timeline for MenuOut
    var tl_menuOpen = new TimelineMax ({
        paused: true,
        // onReverseComplete: menuOut_onReverseComplete
    })
    .fromTo('#navbar', 0.1, {
        maxHeight: '0',
        paddingTop: 0,
        paddingBottom: 0
    }, {
        maxHeight: '800px',
        paddingTop: '25px',
        paddingBottom: '25px',
        // ease: Back. easeOut.config(1)
    }, 0)
    .staggerFromTo(stagger_li, 0.075, {
        autoAlpha: 0,
        yPercent: -100
    }, {
        autoAlpha: 1,
        yPercent: 0,
        // ease: Back.easeOut.config(1)
    }, 0.03);

    // Hide Menu SubSections
    $('#menu-main > li.multilevel ul').css('display', 'none');


    // helper Functions for TL MenuOut
    var menuOut_onStart = function () {
        setTimeout(function () {
            $('#nav-section').addClass('nav-active');
        }, 50);
        $("nav").css('display', 'block');
        menu_opened = true;
        if (window.devicetype != 'non-touch') {
            $('#btn-menu').removeClass('clear-hover');
        }
    };
    var menuOut_onReverseStart = function () {
        $('#nav-section').removeClass('nav-active');
    };
    var menuOut_onReverseComplete = function () {
        $('.li-active').children('ul').toggle();
        $('.li-active').siblings().toggle();
        $('.li-active').parent().closest('li').children('a').toggle();
        $('.li-active').removeClass('li-active');
        $('#nav-section').removeClass('nav-2nd-lvl');
        $("nav").toggle();
        if (window.devicetype != 'non-touch') {
            $('#btn-menu').addClass('clear-hover');
        }
        menu_opened = false;
        menu_lvl = 0;
    };



    // helper Fn menu_toggle
    var menu_toggle = function (li_clicked, slide_direction) {
        if (slide_direction == 1){
            // show child ul
            $("ul", li_clicked).first('ul').toggle();
            // hide siblings
            li_clicked.siblings().toggle();
            if (menu_lvl !== 0) {
                // hide parrent li a
                li_clicked.parent().parent('li').children('a').toggle();
            }
            // Css Class/IDs Changes //
            li_clicked.addClass('li-active');
            // menu_lvl Up
            menu_lvl++;
            if (menu_lvl === 1) {
                $('#nav-section').addClass('nav-2nd-lvl');
            }

        } else if (slide_direction == -1) {
            // hide child ul
            $('.li-active').eq(menu_lvl - 1).children('ul').toggle();
            // show siblings
            $('.li-active').eq(menu_lvl - 1).siblings().toggle();
            // show parent a
            $('.li-active').eq(menu_lvl - 1).parent().closest("li").children('a').toggle();
            // Class Changes //
            $('.li-active').eq(menu_lvl - 1).removeClass('li-active');
            // menu_lvl Dwn
            menu_lvl--;
            if (menu_lvl === 0) {
                $('#nav-section').removeClass('nav-2nd-lvl');
            }
        }
        console.log('menu_toggle, menu lvl:' + menu_lvl);
    };

    var menu_busy_toggle = function (b) {
        menu_busy = b;
        console.log ('menu_busy: ' + b);
    };
    var tl_menuSlide = new TimelineMax({
    });

    // Timeline Controller
    var tl_menu_toggle_ctrl = function (li_clicked, obj_slideOut, obj_slideIn, slide_direction) {
        // prepare Timeline menuSlide

        tl_menuSlide .call(menu_busy_toggle, [true])
            .staggerFromTo(obj_slideOut, 0.1, {
                x: 0,
                autoAlpha: 1
            }, {
                x: slide_direction*-100,
                autoAlpha: 0,
                ease: Back.easeOut.config(1)
            }, 0.05)
            .call(menu_toggle, [li_clicked, slide_direction])
            .staggerFromTo(obj_slideIn, 0.1, {
                x: slide_direction*100,
                autoAlpha: 0
            }, {
                x: 0,
                autoAlpha: 1,
                ease: Back.easeOut.config(1)
            }, 0.05)
            .set(obj_slideOut, {
                x: 1,
                autoAlpha: 1
            })
            .call(menu_busy_toggle, [false]);
    };

    var menuClose = function (t) {
        console.log('menu_mob close called');
        if (menu_opened == true) {
            tl_menuOpen.reverse(t);
            menuOut_onReverseStart();
            setTimeout(function () {
                menuOut_onReverseComplete();
            }, (t*100));
        }
    };

    var menuOpen = function () {
        tl_menuOpen.restart();
        menuOut_onStart();
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 2.2.) Menu Toggle/Slide Functions
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //on menu-btn click or click outside – TL: menuOut
    $(document).mouseup(function (e) {
            // while Menu is opened: click outside nav or on Menu Button
            if (!$('#navbar').is(e.target) && $('#navbar').has(e.target).length === 0 && !$('#btn-menu').is(e.target) && menu_opened === true ||
                $('#btn-menu').is(e.target) && menu_opened === true) {
                menuClose(0.15);

                // Menu is closed: click on menu-btn
            } else if ($('#btn-menu').is(e.target) && menu_opened === false) {
                menuOpen();
            }
    });

    // on click on link in Menu
    $('#menu-main > li a').on('click', function (e) {
        // get clicked li
        let li_clicked = $(this).parent('li');
        let is_multiLvl = li_clicked.hasClass('multilevel');
        let is_active = li_clicked.hasClass('li-active');

        let parent_is_active = li_clicked.parent().parent('li').hasClass('li-active');
        let is_singlelink = li_clicked.parent('ul').is('#menu-main');

        // if link is .multilvl and NOT .activated -> toggle Menu
        if (is_multiLvl && !is_active) {
            // prevent link target
            e.preventDefault();
            e.stopPropagation();

            if (menu_busy === false) {
                let obj_slideIn = li_clicked.children("ul").children('li');
                let obj_slideOut = li_clicked.siblings();
                tl_menu_toggle_ctrl(li_clicked, obj_slideOut, obj_slideIn, 1);
            }
        }
        console.log('Menu Slide Next , menu_lvl: ' + menu_lvl);

        // if is single link or active link -> close Menu
        if (parent_is_active &&  !(is_multiLvl ) || is_active || (is_singlelink && !(is_multiLvl ))) {
            menuClose(0);
        }
    });

    // on click on back Button
    $('#menu-back').on('click', function () {
        if (menu_busy === false) {
            let obj_slideOut = $('.li-active').eq(menu_lvl - 1).children('ul').first().children('li');
            // $('.li-active').eq(menu_lvl - 1).children('ul').children('li').css('background-color','pink')
            console.log('slideOut obj: ' + obj_slideOut);
            let obj_slideIn = $('.li-active').siblings();
            tl_menu_toggle_ctrl( $('.li-active'), obj_slideOut, obj_slideIn, -1);
        }
    });
    menu_mob_kill = function () {
        console.log('menu_mob_kill');

        menuClose(0);
        menuOut_onReverseStart();
        menuOut_onReverseComplete();

        gsap.set('nav', {clearProps: 'all'});
        gsap.set('#menu-main', {clearProps: 'all'});
        gsap.set('#menu-main li', {clearProps: 'all'});
        gsap.set('#menu-main a', {clearProps: 'all'});
        gsap.set('#menu-main ul', {clearProps: 'all'});
    };
};

var menu_dsk = function () {
    console.log('menu_dsk active');
    // set vars
    var active_li;
    var target_ul;
    var target_li;

    // document.getElementById("menu-back").innerHTML = "up";
    // Create Timeline for Menu open
    var tl_menuOpen = new TimelineMax ({
        paused: true
    });
    // Create Timeline Toogle MenuPoints
    var tl_menuToggle_up = new TimelineMax ({
        paused: true
    });
    var tl_menuToggle_dwn = new TimelineMax ({
        paused: true
    });

    // function for MenuOpen
    var menuOpen = function (target_ul, target_a) {
        // Timeline for MenuOut
        tl_menuOpen.from(target_ul, 0.15, {
            height: 0,
            autoAlpha: 0
        }, 0)
        .staggerFrom(target_a, 0.15, {
            autoAlpha: 0,
            yPercent: -20
        }, 0.05);
        tl_menuOpen.play();
        menu_opened = true;
        menu_lvl = 1;

        $('#nav-section').addClass('nav-2nd-lvl');
    };


    // Function: Add Link from Parent li > a to ul
    var add_link = function (e, active_li) {
        // alert('link_add creation');
        var target_ul = active_li.children('ul');
        // create Link Add for Sub UL
        var link = e.target || e.srcElement;
            link = link.outerHTML;
        var link_add = $('<li></li>').attr('class', 'link-add');
            link_add.prepend(link);

        target_ul.prepend(link_add);

    };

    var menuToggle = function (clicked_li, direction) {
        // set Vars
        var active_ul;
        var active_li;
        var active_a;
        var target_a;


        if (direction === 'down') {
            // set Vars
            active_ul = clicked_li.parent('ul');
            active_li = clicked_li.parent('ul').children('li');
            active_a = clicked_li.parent('ul').children('li').children('a');
            target_a = clicked_li.children('ul').find('a');

            clicked_li.addClass('clicked-li');
                // Timeline for MenuOut
            tl_menuToggle_dwn
            .fromTo(active_a, 0.15, {
                yPercent: 0,
                autoAlpha: 1
            }, {
                yPercent: -200,
                autoAlpha: 0
            }, 0)
            .set (active_a, {
                className: '+=hidden'
            }, 0.15)
            .set (active_ul, {
                className: '+=no-background'
            }, 0)
            .fromTo(active_a, 0.15, {
                yPercent: 100,
                autoAlpha: 0
            }, {
                yPercent: 0,
                autoAlpha: 1
            }, 0.15)
            .staggerFromTo(target_a, 0.15, {
                yPercent: 20,
                autoAlpha: 0
            }, {
                yPercent: 0,
                autoAlpha: 1
            }, 0.05);

            tl_menuToggle_dwn.play();
            if (menu_lvl == 2 ) {
                $('#nav-section').addClass('nav-3rd-lvl');
            }
            if (menu_lvl == 1 ) {
                $('#nav-section.nav-2nd-lvl').removeClass('nav-2nd-lvl');
                $('#nav-section').addClass('nav-3rd-lvl');
            }
            menu_lvl ++;

        } else if (direction === 'up') {
            console.log('direction: ' + direction + ', clicked_el: ' + clicked_li);

            // set Vars;
            target_ul = clicked_li.parent('ul');
            active_ul = clicked_li.children('ul:first');
            active_li = clicked_li.parent('ul').children('li');
            active_a = clicked_li.children('ul').children('li').children('a');
            target_a = clicked_li.parent('ul').children('li').children('a');

            var active_linkAdd = clicked_li.children('ul:first').children('li.link-add');
            active_linkAdd.remove();

            clicked_li.removeClass('clicked-li');


            // Timeline for MenuOut
            target_a.removeClass('hidden');
            target_ul.removeClass('no-background');
            tl_menuToggle_up
            .fromTo(active_a, 0.25, {
                yPercent: 0,
                autoAlpha: 1
            }, {
                yPercent: 100,
                autoAlpha: 0
            }, 0)
            .staggerFromTo(target_a, 0.15, {
                yPercent: -20,
                autoAlpha: 0
            }, {
                yPercent: 0,
                autoAlpha: 1
            }, 0.05);

            tl_menuToggle_up.play();
            if (menu_lvl === 2 ) {
                $('#nav-section.nav-3rd-lvl').removeClass('nav-3rd-lvl');
                $('#nav-section').addClass('nav-2nd-lvl');
            }
            menu_lvl --;


        }
    };


    // On Click on top level Link
    $('#menu-main li.multilevel > a').on('click', function (e) {
        // prevent click Action
        e.preventDefault();

        // if clicked link is not active
        if  (!$('.li-active').is(e.target) && !$('.li-active a').is(e.target)){

            // prepare Vars for TL
            active_li = $(this).parent('li:first');
            target_ul = active_li.children('ul');
            // Add Link to Sub UL

            target_li = target_ul.children('li');
            var target_a = target_li.children('a');
            target_ul.css('display', 'inline');
            target_ul.children('li').css('display', 'inline');

            // target_ul.prepend(link_add);
            add_link(e, active_li);

            menuOpen(target_ul, target_a);

            // Add li-active to active li
            active_li.addClass('li-active');

            menu_lvl = 1;
            // toggle nav Section to nav3rd-level
            $('#nav-section').addClass('nav-2nd-lvl');
        }
    });

    // On Click on sub Level link
    $('#menu-main li.multilevel > ul a').on('click', function (e) {
        if ($(this).parent('li').hasClass('multilevel')) {
            e.preventDefault();
            // Add Link from Parent li > a t0 ul
            add_link(e, $(this).parent('li:first'));
            // open sub-ul
            menuToggle($(this).parent('li'), 'down');
            // toggle nav Section to nav3rd-level
            $('#nav-section').addClass('nav-3rd-lvl');
            $('#nav-section.nav-2nd-lvl').removeClass('nav-2nd-lvl');
            menu_lvl ++;
            console.log('menu_lvl: ' + menu_lvl);
        }
    });

    // On Click on MenuBack
    $('#menu-back').on('click', function (e) {
        menu_lvl --;
        menuToggle($('#menu-main .li-active .clicked-li:last'), 'up');
    });

    //on Click Outside of Menu -> Close Menu
    $(document).mouseup(function (e) {
        console.log('clickTarget: ' + e.target);
        // while Menu is opened: click outside nav or on Menu Button
        if (menu_opened === true && !$('.li-active').is(e.target) && !$('.li-active ul').is(e.target) && !$('#menu-main > .li-active a').is(e.target) && !$('#menu-main li').is(e.target) && !$('#menu-main ul').is(e.target) && !$('#menu-back').is(e.target)){
            menuClose();
        }
    });

    // function for Menu Close and Reset Settings
    var menuClose = function () {
        console.log('menu_dsk close called');
        //Reset GSAP Transformations
        if ($(".li-active")[0]) {
            gsap.set('#menu-main .li-active a', {
                clearProps: true
            });
            gsap.set('#menu-main .li-active li', {
                clearProps: true
            });
        }

        // Hide Menu & Remove active class
        $('#menu-main .li-active').children('ul').css('display', 'none');
        $('#menu-main .li-active').removeClass('li-active');

        // Reset Classes & Remove added Elements
        $('#menu-main .clicked-li').removeClass('clicked-li');
        $('#menu-main .hidden').removeClass('hidden');
        $('#menu-main .no-background').removeClass('no-background');
        $('#menu-main').find('.link-add').remove();
        $('#nav-section.nav-2nd-lvl').removeClass('nav-2nd-lvl');
        $('#nav-section.nav-3rd-lvl').removeClass('nav-3rd-lvl');

        menu_opened = false;
        // tl_menuToggle.clear();
    };

    // var nav_path_scrub = () => {
    //     console.log('nav_path_scrub');

    //     var nav_path_scrub_tl  = new TimelineMax ({
    //         scrollTrigger: {
    //             trigger: ".intro",
    //             pin: false,   // pin the trigger element while active
    //             start: "top top", // when the top of the trigger hits the top of the viewport
    //             end: "+=500", // end after scrolling 500px beyond the start
    //             scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    //             markers: false,
    //             // once: true,
    //             // snap: {
    //             //   snapTo: "labels", // snap to the closest label in the timeline
    //             //   duration: {min: 0.2, max: 3}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
    //             //   delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
    //             //   ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
    //             // }
    //             // onEnter: nav_up_tl.play()
    //         }
    //     })
    //     .to(['.nav-path'], {
    //         top: '-30px',
    //         ease: "power1.out",
    //         duration: 1
    //     }, 0);
    //     // .to(['main'], 0.75, {
    //     //     css:{marginTop:0},
    //     //     ease: "power1.out",
    //     //     duration: 1
    //     // }, 0);
    // }
    // nav_path_scrub();

    menu_dsk_kill = function () {
        console.log('menu_dsk_kill');
        tl_menuOpen.kill();
        tl_menuToggle_dwn.kill();
        tl_menuToggle_up.kill();

        gsap.set('nav', {clearProps: true});
        gsap.set('#menu-main', {clearProps: true});
        gsap.set('#menu-main li', {clearProps: true});
        gsap.set('#menu-main a', {clearProps: true});
        menuClose();
    };
};


////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 2.1.) DeviceType & Viewport Check
////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Viewport Check
var mq_992 = window.matchMedia('(min-width: 992px)');
var mediaQueryToggle = function () {
    console.log('MediaSize Query hit,  menu_opened: ' + menu_opened);
    var e;
    if (mq_992.matches) {
        location.reload();
    } else {
        location.reload();
    }
};
// mediaQueryToggle();
mq_992.addEventListener('change', mediaQueryToggle);


// delete eomty ps
var erase_empty_p = function () {
    // console.log( $('p:empty'));
    $('p:empty').each(function (i) {
        $(this).remove();
    });
};



////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// X.) INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////////

jQuery(document).ready(function () {
    if(document.getElementById("menu-main")){
        if (mq_992.matches) {
            menu_dsk();
        } else {
            menu_mob();
        }
    }

    jQuery(window).on('reload-navigation', function() {
        if(document.getElementById("menu-main")){
            if (mq_992.matches) {
                menu_dsk();
            } else {
                menu_mob();
            }
        }
    });
});

// Body id Change for Scroll-Behavios Settings
jQuery(document).ready(function () {
    if($(".atlas-hero").length) {
        $('html').attr('id', 'atlas');
    }

    if($(".event-hero").length) {
        $('html').attr('id', 'events');
    }
    erase_empty_p();
});
