/*jshint browser: true */
/*jshint esversion: 6 */

import gsap from "gsap";
import { EasePack } from "gsap/EasePack";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {Back, TimelineMax} from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(EasePack, ScrollToPlugin, ScrollTrigger);

(function () {
    if (typeof window.CustomEvent === 'function') return false;
    function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }
    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
})();

window.$ = window.jQuery = require('jquery');

const moment = require('moment');

let today = new Date();
let currentMonth = today.getMonth();
let currentYear = today.getFullYear();
let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];



////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1.) Event related Animations
////////////////////////////////////////////////////////////////////////////////////////////////////////////

let info_opened = false;

let eventAnimations = function () {

    // create Info Out Timeline
    window.tl_event_openInfo = new TimelineMax({
        paused: true,
        delay: 0.5
    })
    .from('#event-info', 0.5, {
        xPercent: -100,
        autoAlpha: 0,
        ease: Back.easeOut.config(0.75)
    });

    // window.tl_event_openInfo_dsk = new TimelineMax({
    //     paused: true,
    //     // delay: 0.5
    // })
    // .from('#event-info', 0.5, {
    //     xPercent: -100,
    //     autoAlpha: 0,
    //     ease: Back.easeOut.config(0.75)
    // });

    // open .event-info
    $('.event-info-open').on('click', function (e) {
        let open_eventInfo = function () {
            if (!info_opened) {
                tl_event_openInfo.timeScale(1).play(0);
                info_opened = true;
            }
        };
        let close_eventInfo = function () {
            if (info_opened) {
                tl_event_openInfo.seek(0).pause(0);
                info_opened = false;
            }
        };

        // scroll to Calendar
        gsap.to(window, {
            duration: 0.75,
            scrollTo: '#event-anchor',
            ease: "power2.inOut",
            onStart: close_eventInfo,
            onComplete: open_eventInfo
        });

        let id = $(this).attr('data-event');
        $('.event-info-block').hide();
        $('#event-info-block-'+id).show();

        let item = window.events.find(x => x.ID == id);
        let parts = item.date.split('.');
        showCalendar(parseInt(parts[1]-1), parseInt(parts[2]));
    });

    // close .event-info
    $('.event-info-close').on('click', function (e) {
        window.info_opened = false;
        tl_event_openInfo.delay(5).timeScale(2).reverse();
    });


    // Animation of Date Cont to show start / end Date
    if ($('.date-cont-multiday').length > 0) {
        window.tl_event_dateTicker = new TimelineMax({
            repeat: -1,
            repeatDelay: 1,
            yoyo: true
        })
        .to('.date-cont-multiday', 2, {
            yPercent: -50,
            ease: "power2.inOut",
        }, 0);
    }
};

function next() {
    currentYear = (currentMonth === 11) ? currentYear + 1 : currentYear;
    currentMonth = (currentMonth + 1) % 12;
    showCalendar(currentMonth, currentYear);
}

function previous() {
    currentYear = (currentMonth === 0) ? currentYear - 1 : currentYear;
    currentMonth = (currentMonth === 0) ? 11 : currentMonth - 1;
    showCalendar(currentMonth, currentYear);
}

function showCalendar(month, year) {
    let monthStr = (parseInt(month) < 9) ? '0'+(1+month) : (1+month);
    let firstDay = (new Date(year, month)).getDay();
    let daysInMonth = 32 - new Date(year, month, 32).getDate();
    let dayslastMonth = 32 - new Date(year, month -1, 32).getDate();

    // body of the calendar
    let tbl = document.getElementById("calendar-body");

    // clearing all previous cells
    tbl.innerHTML = "";

    // filing data about month and in the page via DOM.
    let monthAndYear = document.getElementById('month');
    monthAndYear.innerHTML = months[month] + " " + year;

    // creating all cells
    let date = 1;
    for (let i = 0; i < 6; i++)
    {
        // creates a table row
        let row = document.createElement('div');
        row.className='datepicker-row';

        //creating individual cells, filing them up with data.
        for (let j = 0; j < 7; j++)
        {
            let dateStr = (date < 10) ? '0'+date : date;
            let yearPlus = 0;

            if (i === 0 && j < firstDay) {
                let lastMonth = parseInt(month) - 1;
                yearPlus = 0;
                if (lastMonth < 0) { yearPlus = 1; lastMonth = 11; }
                let lastMonthStr = (lastMonth < 9) ? '0'+(1+lastMonth) : (1+lastMonth);
                let day = (dayslastMonth - firstDay + 1) + j;
                let dayStr = (day < 10) ? '0'+day : day;
                let cell = document.createElement("div");
                cell.className='datepicker-day label past-month'+dayStr;
                cell.setAttribute('data-date', dayStr+'.'+lastMonthStr+'.'+(year - yearPlus));
                cell.setAttribute('aria-hidden', 'true');
                let cellText = document.createTextNode(dayStr);
                cell.appendChild(cellText);
                row.appendChild(cell);
            } else if (date > daysInMonth) {
                if (j < 7) {
                    let nextMonth = parseInt(month) + 1;
                    if (nextMonth > 11) { yearPlus = 1; nextMonth = 0; }
                    let nextMonthStr = (nextMonth < 9) ? '0'+(1+nextMonth) : (1+nextMonth);
                    let day = date - daysInMonth;
                    let dayStr = (day < 10) ? '0'+day : day;
                    let cell = document.createElement("div");
                    cell.className='datepicker-day label next-month'+(dayStr);
                    cell.setAttribute('data-date', dayStr+'.'+nextMonthStr+'.'+(yearPlus + year));
                    cell.setAttribute('aria-hidden', 'true');
                    let cellText = document.createTextNode(dayStr);
                    cell.appendChild(cellText);
                    row.appendChild(cell);
                    date++;
                } else {
                    break;
                }
            } else {
                let cell = document.createElement("div");
                cell.className='datepicker-day label dayofmonth'+date;
                cell.setAttribute('id', 'dayofmonth'+dateStr);
                cell.setAttribute('role', 'button');
                cell.setAttribute('data-date', dateStr+'.'+monthStr+'.'+year);
                cell.setAttribute('aria-label', date + '. ' + months[month]);
                let cellText = document.createTextNode(date);
                if (date === today.getDate() && year === today.getFullYear() && month === today.getMonth()) {
                    cell.classList.add("current");
                } // color today's date
                cell.appendChild(cellText);
                row.appendChild(cell);
                date++;
            }
        }
        tbl.appendChild(row); // appending each row into calendar body.
    }

    window.events.filter(function(item) {
        let curDate = moment(item.date, 'DD.MM.YYYY');
        let endDate = moment(item.enddate, 'DD.MM.YYYY');

        let cell = window.jQuery('div').find("[data-date='" + item.date + "']");
        if (cell.length > 0) {
            cell.addClass("begin");
        }

        while(moment(curDate).isSameOrBefore(endDate)) {
            let cell = window.jQuery('div').find("[data-date='" + moment(curDate).format('DD.MM.YYYY') + "']");
            if (cell.length > 0) {
                cell.addClass("begin");
            }
            curDate.add(1, 'days');
        }
    });
}

$('.datepicker-body').on('click', '.datepicker-day', function() {

    //let active = document.getElementsByClassName("begin");
    //if (active.length > 0) active[0].classList.remove('begin')
    let curDate = moment(this.getAttribute('data-date'), 'DD.MM.YYYY');
    let eventFound = null;

    window.events.filter(function(item) {
        if (moment(curDate).isSameOrAfter(moment(item.date, 'DD.MM.YYYY')) && moment(curDate).isSameOrBefore(moment(item.enddate, 'DD.MM.YYYY'))) {
            eventFound = item;
        }
    });

    if (eventFound != null) {

        let open_eventInfo = function () {
            $('#event-info-block-'+eventFound.ID).show();
            tl_event_openInfo.delay(0).timeScale(1).play(0);
        };
        let close_eventInfo = function () {
            tl_event_openInfo.seek(0).pause(0);
            $('.event-info-block').hide();
        };

        gsap.to(window, {
            duration: 0.75,
            scrollTo: '#event-anchor',
            ease: "power2.inOut",
            onStart: close_eventInfo,
            onComplete: open_eventInfo
        });
    }
});

let int = null;

$('input[name="search-input"]').on("keyup", function() {
    let self = this;
    clearTimeout(int);

    int = setTimeout(function() {
        filtrate($(self).val().toLowerCase());
    }, 400);
});

function filtrate(searched) {
    $(".event-result").filter(function() {
        let isHidden  = ($('p', this).text().toLowerCase().indexOf(searched) > -1) ? 'block' : 'none';
        let isHidden2 = ($('h3', this).text().toLowerCase().indexOf(searched) > -1) ? 'block' : 'none';

        if (isHidden === 'block' || isHidden2 === 'block') {
            $(this).removeClass('hidden');
        } else {
            $(this).addClass('hidden');
        }

        if ($('.event-filter #select-by-year').val() === 'all') {
            $('.event-results').addClass('reverse');
        } else {
            $('.event-results').removeClass('reverse');
        }
    });
}

$('.event-filter #sort-by-date').on('change', function(e) {
    let val = $(this).val();

    if ($('#select-by-year').val() === 'all') {
        if (val === 'oldest') {
            $('.event-results').addClass('reverse');
        } else {
            $('.event-results').removeClass('reverse');
        }
    } else {
        if (val === 'oldest') {
            $('.event-results').removeClass('reverse');
        } else {
            $('.event-results').addClass('reverse');
        }
    }
});

$('#legend-topic').on('change', function(e) {
    let val = $(this).val();

    if (val === 'all') {
        $('.event-result').show();
        return;
    }

    window.events.forEach(function (item, index) {
        if (item.topic.toLowerCase() === val) {
            $('.event-result-'+item.ID).show();
        } else {
            $('.event-result-'+item.ID).hide();
        }
    });
});

$('.event-filter #select-by-year').on('change', function(e) {
    let val = $(this).val();

    if (val === 'all') {
        window.location.href = '/page/events';
    } else {
        window.location.href = '/page/events/year/' + val;
    }
});

$('#legend-format').on('change', function(e) {
    let val = $(this).val().replace('-', ' ');

    if (val === 'all') {
        $('.event-result').show();
        return;
    }

    window.events.forEach(function (item, index) {
        if (item.type.toLowerCase() === val) {
            $('.event-result-'+item.ID).show();
        } else {
            $('.event-result-'+item.ID).hide();
        }
    });
});

$('#next-month').on('click', function(ev) {
    next();
});

$('#prev-month').on('click', function(ev) {
    previous();
});

if (document.getElementById("calendar-body") != null) {
    showCalendar(currentMonth, currentYear);
}


// Hide Img Container in Event Result Head when img is empty
$(".event-result-img").each(function (l) {
    if ($(this).find('.event-img').attr('src') == '') {
        console.log("empty: " + this.closest('.event-result-img'));
        $(this).hide();
    }
});

// Hide Container in Event Result Details when Map is empty
$(".event-result-details").each(function (l) {
    if ($(this).find('.google-map').is(':empty')) {
        $(this).find('.col-md-6').first().hide();
    }
});

$(".event-info-copy").on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    let _self = this;
    let copyText = 'https://www.isc3.org' + $(_self).attr('href');
    navigator.clipboard.writeText(copyText);

    $(_self).text('Copied Link To Clipboard');

    setTimeout(function() {
        $(_self).text('Copy Event Details Page');
    }, 4000);
});




////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// X.) INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).ready(function () {
    if($(".event-hero").length) {
        eventAnimations();
        console.log('events loaded');
    }
});
