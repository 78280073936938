
window.$ = window.jQuery = require('jquery');
window._ = require('lodash');
window.Popper = require('popper.js');

require('../../public/assets/phonedial/js/intlTelInput-jquery.min');
require('bootstrap');
require('./navigation');
require('./hero');
require('./animation');
require('./atlas');
require('./form-signup');
require('./form-account');
require('./events');
require('./linkcard-handler');
require('./account');
require('./news');
require('./responsive_video');

// clamp min for Text Clamp
require('./clamp.min');
