// Repo Import / uncomment on Upload

import gsap from "gsap";
import { TimelineLite, TweenLite, TimelineMax, Back  } from "gsap";
import { EasePack } from "gsap/EasePack";
gsap.registerPlugin(EasePack);

import'cropperjs/dist/cropper.css';
import Cropper from'cropperjs';
import { fn } from "jquery";
import { intersectionWith } from "lodash";


////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1.) Bopotstrap Modal Custom Animation
////////////////////////////////////////////////////////////////////////////////////////////////////////////
console.log("form-account.js loaded");

var remove_required = function (e) {
    e.find('.required').removeAttr('required');
    e.find('.optional').css('display', 'inline');
};
var add_required = function (e) {
    e.find('.required').attr('required', '');
    e.find('.optional').css('display', 'none');
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// X.) INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).ready(function () {
    if(document.getElementById('form-account')) {

    }
});
