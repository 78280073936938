/*jshint browser: true */
/*jshint esversion: 6 */

// Repo Import / uncomment on Upload

// import gsap from "gsap";
// import { TimelineLite, TimelineMax, Back, Power1, paused, CSSPlugin, yPercent} from "gsap/all";
// import { EasePack } from "gsap/EasePack";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// gsap.registerPlugin(EasePack, ScrollToPlugin);

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1.) Linkcard Handler
// equals the Height of the Text Card Header
////////////////////////////////////////////////////////////////////////////////////////////////////////////
// var linkcard_handler = function () {

//     // get Cards length
//     var cards = $('.link-cards').find('.card').find('.card-head');
//     // Set Up Array
//     let card_heads = [];
//     // push heights in Array
//     cards.each(function (i) {
//         card_heads.push($( this ).innerHeight());
//     });

//     // FN: get maxHeight, set maxHeight to all Card Heads
//     function setHighest () {
//         // alert(card_heads);
//         var maxHeight = Math.max.apply(Math, card_heads);
//         // console.log('maxHeight: ' + maxHeight);

//         cards.each(function (cards) {
//             this.style.height = maxHeight + 'px';
//         });
//     }
//     // FN Call
//     setHighest();
// };


var figcaption_handler = function () {
    // $( 'article' ).not('.img-50').each(function( index ) {
    $( 'article' ).not('.img-50').each(function( i ) {
        // find sections
        var sections = $(this).find('section');
        sections.each(function( i ) {
            console.log('section found');

            if ($(this).has('figure').length && window.matchMedia('(min-width: 768px)').matches) {
                // console.log('mq_768: ' + window.matchMedia('(min-width: 768px)').matches);
                // fimd captions
                var captions = $(this).find('figure').find('figcaption');
                // Set Up Array
                let caption_heights = [];
                // push heights in Array
                captions.each(function (i) {
                    // console.log('caption found');
                    this.style.minHeight = null; //clear previous set minHeights
                    caption_heights.push($( this ).innerHeight());
                });
                // console.log("caption_heights: " +  caption_heights);

                // FN: get maxHeight, set maxHeight to all Card Heads
                function setHighest () {
                    // alert(card_heads);
                    var maxHeight = Math.max.apply(Math, caption_heights);
                    // console.log('maxHeight: ' + maxHeight);

                    captions.each(function (cards) {
                        this.style.minHeight = maxHeight + 'px'; //set minHeights
                    });
                }
                // FN Call
                setHighest();
            }
        });
    });
};

var card_hlheight_handler = function () {
    $( 'article.h-equalheight-12-6-4' ).each(function( i ) {

        // Ammount of Cards with h-Equalheight
        const cardLength =  $(this).find('.h-equalheight').length;

        // Set Steps by Cards/Row
        let step;
        // 3 cards/row
        if (window.matchMedia('(min-width: 992px)').matches) {
            step = 3;
        // 2 cards/row
        } else if (window.matchMedia('(min-width: 768px)').matches && window.matchMedia('(max-width: 991px)').matches) {
            step = 2;
        // 1 card/row
        } else {
            step = 1;
        }

        if (step > 1) {

            let start = 0;
            let end = step;

            for (let i = 0; i <= cardLength; i += step) {

                // Headlines to equal out
                var headlines = $(this).find('.h-equalheight').find('.equalheight');

                // Create Array with Headline Heights / Row
                var headline_heights = [];
                // Push Heights in Array
                $(this).find('.h-equalheight').slice(start,end).each(function (index) {
                    headline_heights.push( $(this).find('.equalheight').innerHeight() );
                });
                // Find highest Headline height
                var maxHeight = Math.max.apply(Math, headline_heights);
                // Kill old Headline Height in Cards in Row
                // $(this).find('.h-equalheight').slice(start,end).find('.equalheight').css('minHeight', 0 );
                // Set new Headline Height in Cards in Row
                $(this).find('.h-equalheight').slice(start,end).find('.equalheight').css('minHeight',  maxHeight );
                //console.log( 'index: ' + i + ' Headline Height: ' + headline_heights + ' start: ' + start + ' end: ' + end);
                // Counter Up -> Restart For Loop
                start += step;
                end += step;
            };
        } else {
            // if only 1 Card/Row Kill Headline minHeight
            $(this).find('.h-equalheight').find('.equalheight').css('minHeight', 0 );
        }

    });
};




// var mq_768 = window.matchMedia('(min-width: 768px)');
// var mediaQueryToggle_768 = function () {
//     console.log('MediaSize Query 768 hit');
//     var e;
//     if (mq_768.matches) {
//         // alert('768 hit')
//     } else {
//         // location.reload();
//     }
// };
// // mediaQueryToggle();
// mq_768.addEventListener('change', mediaQueryToggle_768);





////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// X.) INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).ready(function () {
    if($('figcaption').length) {
        figcaption_handler();
        window.addEventListener('resize', function(event) {
            figcaption_handler();
        }, true);
    }
    if($('.h-equalheight-12-6-4').length) {
        card_hlheight_handler();
        window.addEventListener('resize', function(event) {
            card_hlheight_handler();
        }, true);
    }
});
