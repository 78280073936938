/*jshint browser: true */
/*jshint esversion: 6 */

// Repo Import / uncomment on Upload

import gsap from "gsap";
import { TimelineLite, TweenLite, TimelineMax, Back, Elastic, Power2  } from "gsap";
import { EasePack } from "gsap/EasePack";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(EasePack);
gsap.registerPlugin(ScrollTrigger);

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 1.) Bopotstrap Modal Custom Animation
////////////////////////////////////////////////////////////////////////////////////////////////////////////
console.log("animation.js loaded");

// Botstrap Modal Animation Override
var customModal = function () {
    $(".modal").each(function (l) {
        $(this).on("shown.bs.modal", function (l) {
            var ease = $(this).attr("data-easein");
            // console.log("ani called, ease: " + ease);
            TweenLite.fromTo(this, 0.5, {
                y: "300px",
            }, {
                y: "0px",
                ease: Back.easeOut.config(1),
            });
        });
    });
};


// Copyright Controller
if ($('.copyright.slide-in').length > 0) {
    // set vars
    var copy_el = [],
        copy_el_trgr = [],
        copy_el_tl = [],
        copy_el_twn = [];

    $('.copyright.slide-in').each(function (i ,copy_obj) {
        // Get Elementa
        copy_el[i] =  copy_obj;
        // console.log('el' + i +' : ' + el[i]);
        copy_el_trgr[i] = copy_obj.closest('.no-overflowX');
        // console.log('el_trgr' + i +' : ' + el_trgr[i])

        // set Timeline
        copy_el_tl[i] = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
            trigger:  copy_el_trgr[i],
            pin: false,   // pin the trigger element while active
            start: "top bottom", // when the top of the trigger hits the top of the viewport
            end: "+=100%", // end after scrolling 500px beyond the start
            scrub: false, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            toggleActions: "play none restart none",
            //   snap: {
            //     snapTo: "labels", // snap to the closest label in the timeline
            //     duration: {min: 0.2, max: 3}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
            //     delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
            //     ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
            //   }
            }
          });

        // Animation
        copy_el_tl[i].set( copy_el[i], {
            x:'-65px'
        },0)
        .fromTo( copy_el[i] , 1.5, {
            xPercent: -100
        }, {
            xPercent: 0,
            ease: Elastic.easeOut.config( 1, 0.75)
        },0)
        .to( copy_el[i] , 1, {
            xPercent: -100,
            ease: Power2.easeInOut
        }, 5)
    });
};


// Homepage Icons Scrub Down
if ($('.icn.scrub-dwn').length > 0) {
    // set vars
    var scrub_dwn_el = [],
        scrub_dwn_el_trgr = [],
        scrub_dwn_el_tl = [],
        scrub_dwn_el_twn = [];

    $('.icn.scrub-dwn').each(function (i ,scrub_dwn_obj) {
        // Get Elementa
        scrub_dwn_el[i] = scrub_dwn_obj;
        // console.log('el' + i +' : ' + el[i]);
        scrub_dwn_el_trgr[i] = scrub_dwn_obj.closest('.card-header');
        // console.log('el_trgr' + i +' : ' + el_trgr[i])

        // set Timeline
        scrub_dwn_el_tl[i] = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
            trigger: scrub_dwn_el_trgr[i],
            pin: false,   // pin the trigger element while active
            start: "top bottom", // when the top of the trigger hits the top of the viewport
            end: "+=50%", // end after scrolling 500px beyond the start
            scrub: 3, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // toggleActions: "play none restart none",
            //   snap: {
            //     snapTo: "labels", // snap to the closest label in the timeline
            //     duration: {min: 0.2, max: 3}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
            //     delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
            //     ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
            //   }
            }
          });

        // Animation
        scrub_dwn_el_tl[i].from( scrub_dwn_el[i] , 1, {
            yPercent: -150,
            autoAlpha: 0,
            ease: Power2. easeInOut
        }, 0)
    });
};

// Homepage Icons Wiggle
if ($('.icn.wiggle').length > 0) {
    // set vars
    var wiggle_el = [],
        wiggle_el_trgr = [],
        wiggle_el_tl = [],
        wiggle_el_twn = [];

    $('.icn.wiggle').each(function (i ,wiggle_obj) {
        // Get Elementa
        wiggle_el[i] =  wiggle_obj;
        // console.log('el' + i +' : ' + el[i]);
        wiggle_el_trgr[i] = wiggle_obj.closest('section');
        // console.log('el_trgr' + i +' : ' + el_trgr[i])

        // set Timeline
        wiggle_el_tl[i] = new TimelineMax({
            // repeat:-1,
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger:  wiggle_el_trgr[i],
                pin: false,   // pin the trigger element while active
                start: "top bottom", // when the top of the trigger hits the top of the viewport
                end: "+=100%", // end after scrolling 500px beyond the start
                scrub: false, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                toggleActions: "play none restart none",
                //   snap: {
                //     snapTo: "labels", // snap to the closest label in the timeline
                //     duration: {min: 0.2, max: 3}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //     delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //     ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                //   }
            }
          });

        // Animation
        wiggle_el_tl[i].to( wiggle_el[i] , 0.7, {
            rotation:8,
        },0)
        .to( wiggle_el[i] , 4, {
            rotation:0,
            ease:Elastic.easeOut.config(0.9,0.1)
        });

    });
};

// Icon Slideshow in Circles
$( ".icn-slide" ).each(function(index) {
    /////////// Settings //////////////
     // set time img is shown (sec)
    const delay = 1.25
    // set animation duration (sec)
    const duration = 2
    //////////////////////////////////

    // get ammount of img
    const imgLength = $( this ).find('img').length - 1
    console.log(' length: ' + imgLength)
    // hide imgs
    $( this ).find('img').each(function() {
        $( this ).css('opacity', 0)
    })
    // show first img
    $( this ).find('img').eq(0).css('opacity', '100%')

    let actual = 0
    let next = 1

    const counter = () => {
        actual = next
        if (next < imgLength) {
            next +=1
        } else if (next == imgLength) {
            next = 0
        }
        fadeImgs()
    }

    let fadeImgs = () => {
        console.log('index:' + index + ' '+ 'actual img: ' + actual + ' next img: ' + next + ' next img: ' + next + ' length: ' + imgLength)
        //Gsap Timeline
        let tl = gsap.timeline({
            // duration: 5,
            onComplete: counter
        })
        tl.fromTo($( this ).find('img').eq( actual ), { autoAlpha: 1 }, {autoAlpha: 0 , duration: duration}, delay)
        tl.fromTo($( this ).find('img').eq( next ), { autoAlpha: 0 }, {autoAlpha: 1, duration: duration}, delay)
    }
    if (imgLength >= 1) {
        fadeImgs()
    }
})


// CLAMP – Expand/Collapse Comments Functions
// var collapseComments = function () {
//     let comments = $('.comment-txt');
//     let comments_body = $('.comment-body');
//     for (let i = 0; i < comments.length; i++ ) {
//         $clamp(comments[i], {
//             clamp: 2,
//             useNativeClamp: true,
//         });
//         comments_body[i].innerHTML += '<p><a onclick="expandComment(this)" href="javascript:void(0);" class="read-more">read more</a></p>';
//     }
// };

// var expandComment = function (e) {
//     let comment = $(e).parent('p').parent('div').find('p.comment-txt')[0];
//     // alert('expand click by' + e + 'comment-txt:' + comment);
//     $clamp(comment, {
//         clamp: 100,
//         useNativeClamp: true,
//         animate: true
//     });

//     $(e)[0].className = 'read-less';
//     $(e)[0].innerHTML = "<a href='javascript:void(0);'>read less</a>";
//     $(e).attr('onClick', 'collapseComment(this)');
// };

// var collapseComment = function (e) {
//     let comment = $(e).parent('p').parent('div').find('p.comment-txt')[0];
//     // alert('collapse click by' + e + 'comment-txt:' + comment);
//     $clamp(comment, {
//         clamp: 1,
//         useNativeClamp: true
//     });
//     $(e)[0].className = 'read-more';
//     $(e)[0].innerHTML = "<a href='javascript:void(0);'>read more</a>";
//     $(e).attr('onClick', 'expandComment(this)');
// };

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// X.) INIT FUNCTION
////////////////////////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).ready(function () {
    if(document.getElementsByClassName('modal')){
        customModal();
        $('[data-toggle="tooltip"]').tooltip();
    }
    // if(document.getElementsByClassName('comment')){
    //     collapseComments();
    // }
});
